import { Cart } from "../models/cart";

interface PaymentInfo {
  Text: string;
  Amount: number;
}

interface IPaymentInformationService {
  Get(id: string): Promise<PaymentInfo[]>;
}

/* istanbul ignore next */
class MockPaymentInformationService implements IPaymentInformationService {
  Get(_id: string): Promise<PaymentInfo[]> {
    return Promise.resolve([
      { Text: 'Subtotal:', Amount: 40.0, Tax: 0.0, Quantity: 1 },
      { Text: 'Estimated tax:', Amount: 5.92, Tax: 0.0, Quantity: 1 }
    ]);
  }
}

class PaymentInformationService implements IPaymentInformationService {
  async Get(cartId: string): Promise<PaymentInfo[]> {
    const cart = await fetch(`/api/cart/${cartId}`)
      .then((r) => r.json());
    return PaymentInformationMapper.ToPaymentInfo(cart);
  }
}

class PaymentInformationMapper {
  static ToPaymentInfo(cart: Cart): PaymentInfo[] {
    if (cart == null) {
      return [];
    }

    let totalTaxes = 0;

    const payments = cart.items.flatMap<PaymentInfo>(ci =>
      ci.paymentRequest.transaction.passengers.flatMap<PaymentInfo>(pax =>
        pax.products.map<PaymentInfo>(product => {
          totalTaxes += product.taxes?.reduce((taxes, tax) => taxes + tax.amount, 0);

          return {
            Text: product.name,
            Amount: product.salePrice.amount
          };
        }))
    );

    if (totalTaxes > 0) {
      payments.push({
        Text: "Taxes",
        Amount: totalTaxes
      });
    }

    return payments;
  }
}


export { type IPaymentInformationService, type PaymentInfo, MockPaymentInformationService, PaymentInformationService, PaymentInformationMapper };
