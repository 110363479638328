import { Fragment } from 'react';
import './loading-dots.scss';

const LoadingDots = () => {
    return (
        <Fragment>
            <div id="content-loader" className="loader" data-testid="loading-dots-container">
                <div className="dot-1"></div>
                <div className="dot-2"></div>
                <div className="dot-3"></div>
            </div>
        </Fragment>
    );
}

export { LoadingDots };