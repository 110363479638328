import { useState, useRef, Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDependency } from '../../../plumbing/DependencyContainer';
import { LoadingDots } from '../shared/loading-dots';
import { MessageBar } from '../shared/message-bar';
import { ErrorMessages } from '../../../models/error-messages';
import { SeatHeader } from '../shared/seat-header';
import { ConfirmationInfo } from '../../../models/confirmation-info';
import { PaymentDisplay } from '../shared/payment-display';
import { SentToDisplay } from './sent-to-display';

const SeatUpgradeConfirmation = () => {
    const { serviceFactory } = useDependency();
    const { tx } = useParams();
    const transactionId = useRef(tx as string);
    const [confirmationInfo, setConfirmationInfo] = useState<ConfirmationInfo>();
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<string>();


    // Here to make TypeScript happy. These will always exist
    /* istanbul ignore next */
    if (!transactionId.current) {
        throw new Error('Invalid transaction id!');
    }

    // Here to make TypeScript happy. These will always exist
    /* istanbul ignore next */
    if (!serviceFactory) {
        throw new Error('Service Factory is not configured!');
    }

    useEffect(() => {
        const confirmationService = serviceFactory.GetConfirmationService();
        const getItems = async () => {
            setLoading(true);

            await confirmationService.Get(transactionId.current)
                .then((data) => {
                    if(data.ok) {
                        setConfirmationInfo(data.confirmationInfo);
                    } else {
                        setErrorMessage(ErrorMessages.NotFound);
                    }
                })
                .finally(() => setLoading(false))
        };

        getItems();
    }, [serviceFactory]);

    return (
        <Fragment>
            <div
                aria-live="polite"
                className="content-wrapper">
                <SeatHeader></SeatHeader>
                <div id="content">
                    <MessageBar message={errorMessage} data-testid="message-bar"></MessageBar>

                    {confirmationInfo &&
                        <Fragment>
                            <PaymentDisplay displayInfo={confirmationInfo} titleOverride='Payment Received'></PaymentDisplay>
                            <SentToDisplay sentTo={confirmationInfo.email} />
                        </Fragment>
                    }
                </div>

                {loading && <LoadingDots></LoadingDots>}

            </div>
        </Fragment>
    );
}

export { SeatUpgradeConfirmation };