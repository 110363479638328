import { createContext, ReactNode, useContext } from 'react';
import { ServiceFactory } from './ServiceFactory';

interface ServiceFactoryContext {
  serviceFactory?: ServiceFactory;
}

const DependenciesContext = createContext<ServiceFactoryContext>({});

function useDependency() {
  return useContext(DependenciesContext);
}

interface diProps {
  children: ReactNode;
  serviceFactory: ServiceFactory;
}

const DependencyContainer: React.FunctionComponent<diProps> = ({ children, ...services }) => {
  return <DependenciesContext.Provider value={services}>{children}</DependenciesContext.Provider>;
};

export { useDependency, DependencyContainer };
