import { DisplayInfo } from "../../models/display-info";

interface IDisplayService {
    Get(id: string): Promise<DisplayInfo>;
}

class DisplayService implements IDisplayService {
    async Get(cartId: string): Promise<DisplayInfo> {
        const cart = await fetch(`/api/display/${cartId}`)
            .then((r) => r.json());
        return cart;
    }
}

export { type IDisplayService, DisplayService };
