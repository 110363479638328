import { Fragment } from 'react';
import { DisplayInfo } from '../../../models/display-info';
import { ConfirmationInfo } from '../../../models/confirmation-info';

interface PaymentDisplayProps {
    displayInfo: DisplayInfo | ConfirmationInfo | undefined;
    titleOverride?: string;
}

const PaymentDisplay = (props: PaymentDisplayProps) => {
    const displayInfo = props.displayInfo;

    return (
        <Fragment>
            <div className="summary">
                <div className="section-title">{props.titleOverride ? props.titleOverride : 'Payment'}</div>
                <div className="info">{displayInfo?.description}</div>
                <div id="line-items">
                    <div className="line-item">
                        <div className="type-heading">Type:</div>
                        <div className="price-heading">Total:</div>
                    </div>
                    <div className="line-item">
                        <div className="type bold">Seat upgrade</div>
                        <div className="price bold">{displayInfo?.totalDue.toFixed(2)}</div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export { PaymentDisplay };