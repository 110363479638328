import { Fragment } from 'react';
import './sent-to-display.scss';

interface SentToDisplayProps {
    sentTo: string
}

const SentToDisplay = (props: SentToDisplayProps) => {
    return (
        <Fragment>
            <div className="confirmation-content">
                <div id="line-items">
                    <div className="line-item">
                        <div className="type-heading">Confirmation sent to:</div>
                    </div>
                    <div className="line-item">
                        <div className="type bold">{props.sentTo}</div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export { SentToDisplay, type SentToDisplayProps };