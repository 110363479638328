import { Fragment, useEffect, useRef, useState } from "react";
import { useDependency } from "../../plumbing/DependencyContainer";
import { PaymentInfo } from "../../services/payment-information-service";

interface PaymentSummaryProps {
    transactionId: string
}

const PaymentSummary = (props: PaymentSummaryProps) => {
    const { serviceFactory } = useDependency();

    // Here to make TypeScript happy. These will always exist
    /* istanbul ignore next */
    if (!serviceFactory) {
        throw new Error('Service Factory is not configured!');
    }

    const paymentInformationService = serviceFactory.GetPaymentInformationService();
    const transactionId = useRef(props.transactionId);
    const [lineItems, setLineItems] = useState<PaymentInfo[]>();
    const [total, setTotal] = useState<number>();

    useEffect(() => {
        const getItems = async () => {
            const items = await paymentInformationService.Get(transactionId.current);
            setLineItems(items);
            setTotal(items.map((li) => li.Amount).reduce((a, b) => a + b, 0));
        };

        getItems();
    }, [paymentInformationService]);

    return <section className="summary">
        <h3>Payment summary</h3>

        <dl className="lineItems">
            {lineItems?.map((li, ix) => {
                return (
                    <Fragment key={ix}>
                        <dt data-testid={li.Text}>{li.Text}</dt>
                        <dd>{li.Amount.toFixed(2)}</dd>
                    </Fragment>
                );
            })}
        </dl>

        <dl className="total">
            <dt>Total:</dt>
            <dd>{total?.toFixed(2) ?? 0} USD</dd>
        </dl>
    </section>
}

export { PaymentSummary }
