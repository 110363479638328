import { Cart } from "../models/cart";

interface ICartService {
    Get(id: string): Promise<Cart>;
}

class CartService implements ICartService {
    async Get(cartId: string): Promise<Cart> {
        const response = await fetch(`/api/cart/${cartId}`);
        if(response.status !== 200){
          throw new Error(`${response.status} ${response.statusText}`);
        }
        
        const cart = await response.json();
        return cart;
    }
}

export { type ICartService, CartService };
