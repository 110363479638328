import { ErrorMessages } from "../../models/error-messages";
import { PaymentRequestParams } from "../../models/payment-request-params";
import { PaymentResponse } from "../../models/payment-response";

interface IPaymentService {
    Post(request: PaymentRequestParams): Promise<PaymentResponse>;
}

class PaymentService implements IPaymentService {
    async Post(request: PaymentRequestParams): Promise<PaymentResponse> {
        const result = await fetch('/api/purchase', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
          })
          .then(async response => {
            const mappedResponse: PaymentResponse = {
                ok: response.ok,
                errorMessage: response.ok ? undefined : this.convertErrorMessage(await response.json())
            }
            return mappedResponse;
          })
          .catch((e) =>{
            const mappedResponse: PaymentResponse = {
                ok: false,
                errorMessage: this.convertErrorMessage(e)
            }
            return mappedResponse;
          });
          return result;
    }

    private convertErrorMessage(exceptionContent?: any): string {
        switch (exceptionContent) {
          case 'DoNotHonor':
            return ErrorMessages.DoNotHonor;
          case 'InvalidCardNumber':
            return ErrorMessages.InvalidCardNumber;
          case 'InvalidExpiration':
            return ErrorMessages.InvalidExpiration;
          case 'TooManyAttempts':
            return ErrorMessages.TooManyAttempts;
          case 'Processing':
            return ErrorMessages.Processing;
          default:
            return ErrorMessages.PaymentError;
        }
    }
}

export { type IPaymentService, PaymentService };
