import { useState, useRef, Fragment, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CountdownTimer } from '../shared/countdown-timer';
import { useDependency } from '../../../plumbing/DependencyContainer';
import { DisplayInfo } from '../../../models/display-info';
import { LoadingDots } from '../shared/loading-dots';
import { MessageBar } from '../shared/message-bar';
import { ErrorMessages } from '../../../models/error-messages';
import { SeatHeader } from '../shared/seat-header';
import { PaymentDisplay } from '../shared/payment-display';
import { UpgradeBluefinForm } from './upgrade-bluefin-form';

const SeatUpgradePayment = () => {
    const navigate = useNavigate();
    const { serviceFactory } = useDependency();
    const { tx } = useParams();
    const transactionId = useRef(tx as string);
    const [displayInfo, setDisplayInfo] = useState<DisplayInfo>();
    const [loading, setLoading] = useState(true);
    const [purchaseProcessing, setPurchaseProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();


    // Here to make TypeScript happy. These will always exist
    /* istanbul ignore next */
    if (!transactionId.current) {
        throw new Error('Invalid transaction id!');
    }

    // Here to make TypeScript happy. These will always exist
    /* istanbul ignore next */
    if (!serviceFactory) {
        throw new Error('Service Factory is not configured!');
    }

    const paymentService = serviceFactory.GetPaymentService();

    useEffect(() => {
        const displayService = serviceFactory.GetDisplayService();
        const getItems = async () => {
            setLoading(true);

            await displayService.Get(transactionId.current)
                .then((data) => setDisplayInfo(data))
                .catch(() => setErrorMessage(ErrorMessages.NotFound))
                .finally(() => setLoading(false))
        };

        getItems();
    }, [serviceFactory]);

    const handleBluefinTokenGenerated = (token: string): void => {
        setPurchaseProcessing(true);
        paymentService.Post({
            blueFinId: token,
            transactionId: transactionId.current
        })
        .then(response => {
            if (response.ok) {
                navigate("/seat-upgrade-confirmation/" + tx)
            } else {
                handleError(response.errorMessage);
            }
        })
        .finally(() => setPurchaseProcessing(false));
    }

    const handleError = (error?: string): void => {
        setErrorMessage(error);
    }

    return (
        <Fragment>
            <div
                aria-live="polite"
                className="content-wrapper">
                <SeatHeader></SeatHeader>
                <div id="content">

                    {displayInfo &&
                        <CountdownTimer timeout={displayInfo.expirationTime}></CountdownTimer>
                    }

                    <MessageBar message={errorMessage} data-testid="message-bar"></MessageBar>

                    {displayInfo &&
                        <Fragment>
                            <PaymentDisplay displayInfo={displayInfo}></PaymentDisplay>
                            <UpgradeBluefinForm
                                onBluefinTokenGenerated={handleBluefinTokenGenerated}
                                onError={handleError}
                                loading={purchaseProcessing} />
                        </Fragment>
                    }
                </div>

                {loading && <LoadingDots></LoadingDots>}

            </div>
        </Fragment>
    );
}

export { SeatUpgradePayment };