import { forwardRef, useImperativeHandle, useRef } from "react"
import { AuroInputChanged } from "../../plumbing/AuroEvents"
import { useDependency } from "../../plumbing/DependencyContainer"

interface PaymentData {
    email: string;
}

interface PaymentDataFormHandle {
    getPaymentData(): PaymentData;
}

const PaymentDataForm = forwardRef<PaymentDataFormHandle>((_props, ref) => {
  const { serviceFactory } = useDependency();

  // Here to make TypeScript happy. These will always exist
  /* istanbul ignore next */
  if (!serviceFactory) {
    throw new Error("Service Factory is not configured!");
  }

  const email = useRef<string>();

  useImperativeHandle(
    ref,
    () => ({
      getPaymentData() {
        return {
          email: email.current ?? "",
        };
      },
    }),
    []
  );

  /* istanbul ignore next */
  function setEmail(e: AuroInputChanged) {
    email.current = e.currentTarget.value;
    sessionStorage.setItem("email", e.currentTarget.value);
  }

  function getEmail(): string {
    return email.current ?? sessionStorage.getItem("email") ?? "";
  }

  return (
    <section id="other-fields">
      <h3>Contact information</h3>

      <div className="control">
        <auro-input
          name="email-address"
          data-testid="email-address"
          type="email"
          bordered
          required
          value={getEmail()}
          onInput={setEmail}
        >
          <span slot="label">Email</span>
        </auro-input>
      </div>
    </section>
  );
});

export { PaymentDataForm, type PaymentDataFormHandle }
