import { Fragment, useEffect, useRef, useState } from 'react';
import '../seat-upgrade.scss';
import { useNavigate } from 'react-router-dom';

interface CountdownTimerProps {
    timeout: number;
}

const CountdownTimer = (props: CountdownTimerProps) => {
    const navigate = useNavigate();
    const timerRef = useRef<ReturnType<typeof setInterval> | null>(null);
    // We're using a separate "accessible" time and timer so screen readers won't
    // constantly provide updates to the countdown timer.
    const [accessibleTime, setAccessibleTime] = useState<string>();
    const [timeDisplay, setTimeDisplay] = useState<string>();


    const getTimeRemaining = (secondsSinceEpoch: number) => {
        return secondsSinceEpoch - Math.round(new Date().getTime() / 1000);
    }

    const formatTime = (seconds: number): string => {
        if (seconds < 0) {
            return '0:00';
        }
        // tslint:disable-next-line: no-bitwise
        const hr = ~~(seconds / 3600);
        // tslint:disable-next-line: no-bitwise
        const min = ~~((seconds % 3600) / 60);
        const sec = seconds % 60;
        let sec_min = '';
        if (hr > 0) {
            sec_min += '' + hr + ':' + (min < 10 ? '0' : '');
        }
        sec_min += '' + min + ':' + (sec < 10 ? '0' : '');
        sec_min += '' + sec;
        return sec_min;
    }

    useEffect(() => {
        const initTimer = () => {
            let timeRemaining = getTimeRemaining(props.timeout);
            const initialTime = formatTime(timeRemaining);
            setTimeDisplay(initialTime);
            setAccessibleTime(initialTime)
            const countdownTimer = setInterval(() => {
                timeRemaining--;
                setTimeDisplay(formatTime(timeRemaining));
    
                if (timeRemaining <= 0) {
                    clearInterval(countdownTimer);
                    navigate('/seat-upgrade-timeout');
                }
            }, 1000);
    
            timerRef.current = countdownTimer;
        };

        initTimer();
    }, [props, navigate]);

    return (
        <Fragment>
            <div aria-label={`Complete payment within ${accessibleTime} or your new seats will be lost.`}>
                <div
                    className="message-bar"
                    aria-hidden="true"
                    data-testid="countdown-message">Complete payment within {timeDisplay} or your new seats will be lost.</div>
            </div>
        </Fragment>
    );
}

export { CountdownTimer };