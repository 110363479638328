import { Fragment } from 'react';
import './seat-upgrade-summary.scss';
import { DisplayInfo } from '../../../models/display-info';

interface SummaryDisplayProps {
    displayInfo: DisplayInfo | undefined
}

const SummaryDisplay = (props: SummaryDisplayProps) => {
    const displayInfo = props.displayInfo;

    return (
        <Fragment>
            <div className="summary">
                <div className="section-title">Summary</div>
                <div className="info">{displayInfo?.description}</div>
                <div id="line-items">
                    {displayInfo?.displayFields.map((field) => (
                        <div className="line-item" key={field.primaryText}>
                            <div className="primary">{field.primaryText}</div>
                            <div className="secondary bold">{field.secondaryText}</div>
                            <div className="price bold">{field.amount}</div>
                        </div>
                    ))}
                </div>
            </div>
        </Fragment>
    );
}

export { SummaryDisplay, type SummaryDisplayProps };