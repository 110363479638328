export enum ErrorMessages {
    NotFound = 'Transaction not found. Text 82008 for further assistance.',
    Expired = 'Time expired, transaction can no longer be completed.',
    Unhandled = 'An unexpected error occurred. If this issue persists text 82008 for further assistance.',
    PaymentError = 'Payment error. If this issue persists text 82008 for further assistance.',
    DoNotHonor = 'Credit card declined. Please use a different form of payment.',
    InvalidCardNumber = 'Invalid credit card number.',
    InvalidExpiration = 'Invalid expiration date.',
    TooManyAttempts = 'Too many payment attempts. Text 82008 for further assistance.',
    Processing = 'Previous payment attempt is processing. Do not refresh this page.'
}
