// Following the exhaustive-deps rule here causes the bluefin form to render
// a second time after save. There might be a way to solve this but given the
// nature of the Bluefin form I'm not confident.
/* eslint react-hooks/exhaustive-deps: "off" */

import 'bluefin-shieldconex';
import { Fragment, useEffect, useRef, useState } from 'react';
import './upgrade-bluefin-form.scss';

interface UpgradeBluefinFormProps {
    onBluefinTokenGenerated(token: string): void;
    onError(error: string): void;
    loading: boolean
}

const UpgradeBluefinForm = (props: UpgradeBluefinFormProps) => {
    const scfr = useRef<any>(null);
    const [bluefinRendered, setBluefinRendered] = useState<boolean>(false);

    const submitToBluefin = () => {
        scfr.current.tokenize('echo');
    }

    useEffect(() => {
        const config = {
            baseUrl: process.env.REACT_APP_BLUEFIN_BASE_URL,
            templateId: process.env.REACT_APP_BLUEFIN_ITS_TEMPLATE,
            parent: 'form-parent',
            attributes: {
                width: '100%',
                height: '155px',
                frameborder: 0
            }
        };

        scfr.current = new (window as any).ShieldconexIFrame(config);

        scfr.current.onError = (_echo: any, errors: Array<{ message: string }>) => {
            if (!errors?.length) {
                props.onError('Card information cannot be processed.');
                return;
            }

            props.onError(errors.map((e) => e.message).join('\n'));
        };

        scfr.current.onToken = async (_echo: any, token: string) => {
            props.onBluefinTokenGenerated(token);
        };

        // We want to render the button after the form is rendered so the tab order logic works correctly.
        // If we render the button first the button's tabstop will be before the form, which is weird.
        scfr.current.onRendered = () => {
            setBluefinRendered(true);
        }

        scfr.current.render();

    }, []);

    

    return (
        <Fragment>
            <div className="form-wrapper">
                <div id="form-parent"></div>
            </div>
            {bluefinRendered &&
                <Fragment>
                    {props.loading ?
                        <div
                            className="loader button-loader"
                            data-testid="loading-dots">
                            <div className="dot-1"></div>
                            <div className="dot-2"></div>
                            <div className="dot-3"></div>
                        </div>
                        :
                        <button
                            id="pay"
                            className="button primary"
                            onClick={() => submitToBluefin()}
                            data-testid="pay-button">Purchase</button>
                    }
                </Fragment>
            }
        </Fragment>
    );
}

export { UpgradeBluefinForm, type UpgradeBluefinFormProps };