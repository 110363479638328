import "bluefin-shieldconex";
import { forwardRef, useEffect, useRef, useImperativeHandle } from "react";

interface BluefinFormProps {
    onBluefinTokenGenerated(token: string): void;
    onError(error: string): void;
}

interface BluefinFormHandle {
    submitToBluefin(): void;
}

function replaceSystemName(e:any){
    const error = e.message;
    const replacementMap: Record<string, string> = {
        "CardNumber": "Card Number",
        "ExpirationMonth": "Expiration Month",
        "ExpirationYear": "Expiration Year",
        "NameOnCard": "Name On Card"
    };
    return error.replace(/\w{5,30}(?=')/g, (match: string) => replacementMap[match] || match);
}

/**
 * In this function, IFrame is on the page and can be modified
 */
function onIFrameRender({ iframe }: { iframe: HTMLIFrameElement }) {
  iframe.setAttribute("title", "Credit and debit card form");
}

const BluefinForm = forwardRef<BluefinFormHandle, BluefinFormProps>((props: BluefinFormProps, ref) => {
    const scfr = useRef<any>(null);
    const onError = useRef(props.onError);
    const onBluefinTokenGenerated = useRef(props.onBluefinTokenGenerated);


    useImperativeHandle(ref, () => ({
        submitToBluefin() {
            scfr.current.tokenize('sample#echo');
        }
    }));

    useEffect(() => {
        const config = {
            baseUrl: process.env.REACT_APP_BLUEFIN_BASE_URL,
            templateId: process.env.REACT_APP_BLUEFIN_ECM_TEMPLATE,
            parent: 'bluefin-frame'
        };

        scfr.current = new (window as any).ShieldconexIFrame(config);

        scfr.current.onError = (_echo: any, errors: Array<{ message: string }>) => {
            if (!errors?.length) {
                onError.current("Card information cannot be processed.");
                return;
            }

            onError.current(errors.map((e) => replaceSystemName(e)).join("\n"));
        };

        scfr.current.onToken = async (_echo: any, token: string) => {
            onBluefinTokenGenerated.current(token);
        };

        scfr.current.onRendered = onIFrameRender;

        scfr.current.render();
    }, []);


    return <section>
        <h3 data-testid="bluefin-title">
            Credit or debit card information
        </h3>

        <div id="bluefin-frame" className="shieldconex"></div>
    </section>;
});

export { BluefinForm, type BluefinFormHandle }
