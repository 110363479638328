import { Fragment } from 'react';
import './message-bar.scss';

interface MessageBarProps {
    message?: string
}

const MessageBar = (props: MessageBarProps) => {
    return (
        <Fragment>
            {props.message &&
                <div id="message-area">
                    <div className="message-bar error"  data-testid="message-bar">{props.message}</div>
                </div>
            }
        </Fragment>
    );
}

export { MessageBar, type MessageBarProps };

