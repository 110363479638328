interface TransactionRequest {
  cartId: string;
  eToken: string;
  email: string;
}

interface TransactionResponse {
  Success: boolean;
  ErrorMessage?: string;
}

interface ITransactionProcessor {
  Process(transactionInfo: TransactionRequest): Promise<TransactionResponse>;
}

class TransactionProcessor implements ITransactionProcessor {
  async Process(transactionInfo: TransactionRequest): Promise<TransactionResponse> {

    const getErrorMessage = (error: number): string => {
      // NOTE: Expecting one of these back...
        // public enum CartPurchaseError
        // {
        //     CartNotFound = HttpStatusCode.NotFound, - 404
        //     EmptyCart = HttpStatusCode.BadRequest, - 400
        //     IncompletePurchase = HttpStatusCode.PartialContent - 206
        // }
        switch (error) {
          case 404:
            return "We were unable to locate your transaction.";
          case 400:
            return "We were unable to process your transaction. Please try again.";
          case 206:
            return "We were only able to partially complete your transaction.";
          default:
            return "There was an error processing your transaction.";
        }
      }
      const response = await fetch(`/api/purchase/cart`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(transactionInfo)
      });

      if(response.status !== 200){
        return {
          Success: false,
          ErrorMessage: getErrorMessage(response.status)
        } 
      }

      const result = await response.json()
      return {
        Success: result.wasSuccess,
        ErrorMessage: ""
      }
  }
}

/* istanbul ignore next */
class MockTransactionProcessor implements ITransactionProcessor {
  async Process(transactionInfo: TransactionRequest): Promise<TransactionResponse> {
    await new Promise(r => setTimeout(r, 3000));

    alert(`Mock Processing Transaction: ${JSON.stringify(transactionInfo)}`);

    return {
      Success: true
    };
  }
}

export { type ITransactionProcessor, type TransactionRequest, type TransactionResponse, MockTransactionProcessor, TransactionProcessor };
