import { useState, useRef, Fragment, useEffect } from 'react';
import './make-payment.scss';
import { useDependency } from '../../plumbing/DependencyContainer';
import { useParams } from 'react-router-dom';
import { BluefinForm, BluefinFormHandle } from './bluefin-form';
import { PaymentSummary } from './payment-summary';
import { PaymentDataForm, PaymentDataFormHandle } from './other-payment-data';
import { useErrorBoundary } from 'react-error-boundary';
import { TransactionHeader } from './transaction-header';
const enum CurrentAction {
  UserInput = "UserInput",
  Processing = "Processing",
  Completed = "Completed",
  TimingOut = "TimingOut",
  Failed = "Failed",
}

const MakePayment = () => {
  const { serviceFactory } = useDependency();
  const { tx } = useParams();
  const transactionId = useRef(tx as string);

  // Here to make TypeScript happy. These will always exist
  /* istanbul ignore next */
  if (!transactionId.current) {
    throw new Error('Invalid transaction id!');
  }

  // Here to make TypeScript happy. These will always exist
  /* istanbul ignore next */
  if (!serviceFactory) {
    throw new Error('Service Factory is not configured!');
  }

  const { showBoundary } = useErrorBoundary();
  const cartService = serviceFactory.GetCartService();
  const transactionProcessor = serviceFactory.GetTransactionProcessor();
  const [errorMessage, setErrorMessage] = useState<string>();
  const bluefinControl = useRef<BluefinFormHandle>(null);
  const paymentDataForm = useRef<PaymentDataFormHandle>(null);
  const [currentAction, setCurrentAction] = useState<CurrentAction>(CurrentAction.UserInput);
  const onComplete = useRef("");
  const onFailed = useRef("");
  const attempts = useRef(0);

  useEffect(() => {
    const getItems = async () => {
      try {
        const cart = await cartService.Get(transactionId.current);
        onComplete.current = (cart.onComplete ?? "").replaceAll("<CARTID>", transactionId.current);
        onFailed.current = (cart.onError ?? "").replaceAll("<CARTID>", transactionId.current);
      }
      catch(err) {
        showBoundary(err);
      }
    };
 
    getItems();
  }, [cartService,showBoundary]);

  // Cannot test the bluefin component
  /* istanbul ignore next */
  const submitPayment = () => {
    setErrorMessage('');
    bluefinControl.current?.submitToBluefin();
  };

  // Cannot test the bluefin component
  /* istanbul ignore next */
  const bluefinTokenGenerated = async (bluefinToken: string) => {
    const paymentData = paymentDataForm.current?.getPaymentData()

    if (!paymentData || !paymentData.email) {
      setErrorMessage('Please provide your email address.');
      return;
    }

    setCurrentAction(CurrentAction.Processing);

    const response = await transactionProcessor.Process({
      eToken: bluefinToken,
      email: paymentData.email,
      cartId: transactionId.current
    });

    if (response.Success) {
      setCurrentAction(CurrentAction.Completed);
      await new Promise(r => setTimeout(r, 3000));
      window.location.assign(onComplete.current);
      return;
    }

    if (attempts.current === 2) {
      // NOTE: Only let them try and fail 3 times, then redirect back
      //       to the on error URL.
      setCurrentAction(CurrentAction.Failed);
      await new Promise(r => setTimeout(r, 3000));
      window.location.assign(onFailed.current);
      return;
    }

    attempts.current += 1;
    setErrorMessage("We had some trouble processing your card. Please try again.");
    setCurrentAction(CurrentAction.UserInput);
  }

  return (
    <Fragment>
      <TransactionHeader />
      {(currentAction.valueOf() === CurrentAction.UserInput) && <div className="wrapper">

        {errorMessage && <auro-alert type="error">{errorMessage}</auro-alert>}

        <aside>
          <h2>Card information</h2>
          <p>Complete your payment information to continue.</p>
        </aside>

        <main>
          <BluefinForm ref={bluefinControl} onBluefinTokenGenerated={bluefinTokenGenerated} onError={setErrorMessage}></BluefinForm>

          <PaymentDataForm ref={paymentDataForm}></PaymentDataForm>

          <PaymentSummary transactionId={transactionId.current}></PaymentSummary>

          <section className="total">
            <p className="disclaimer">
              By clicking 'Purchase', you accept the <a href="https://www.alaskaair.com/content/legal/website-terms-of-use" target="_blank" rel="noreferrer">Terms & Conditions</a>.
            </p>

            <auro-button onClick={submitPayment} fluid>
              Purchase
            </auro-button>
          </section>
        </main>
      </div>}

      {(currentAction.valueOf() === CurrentAction.Processing) && <div className="actionMessages">
        <auro-loader pulse onlight sm></auro-loader>
        <h2>Processing...</h2>
      </div>}

      {(currentAction.valueOf() === CurrentAction.Failed) && <div className="actionMessages">
        <auro-icon
          style={{ width: "4rem" }}
          customSize
          category="alert"
          name="information-filled"
          error >
        </auro-icon>

        <h2>Payment Failed</h2>

        <p>
          <a href={onFailed.current}>
            Redirecting shortly.
          </a>
        </p>
      </div>}

      {(currentAction.valueOf() === CurrentAction.Completed) && <div className="actionMessages">
        <auro-icon
          style={{ width: "4rem" }}
          customSize
          category="interface"
          name="check-filled"
          success >
        </auro-icon>

        <h2>Payment accepted</h2>

        <p>
          <a href={onComplete.current}>
            Continue to checkout.
          </a>
        </p>
      </div>}
    </Fragment>
  );
};

export { MakePayment };
