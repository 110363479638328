import { Fragment } from 'react';
import './seat-header.scss';
import headerLogo from '../../../assets/alaska-logo.svg';

const SeatHeader = () => {
    return (
        <Fragment>
            <div id="header">
                <span id="logo">
                    <img src={headerLogo} alt="Alaska Airlines" aria-hidden="true" />
                </span>
                <span className="site-title">Payment</span>
            </div>
        </Fragment>
    );
}

export { SeatHeader };