import { useState, useRef, Fragment, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './seat-upgrade-summary.scss';
import { CountdownTimer } from '../shared/countdown-timer';
import { useDependency } from '../../../plumbing/DependencyContainer';
import { DisplayInfo } from '../../../models/display-info';
import { LoadingDots } from '../shared/loading-dots';
import { MessageBar } from '../shared/message-bar';
import { ErrorMessages } from '../../../models/error-messages';
import { SummaryDisplay } from './summary-display';
import { SeatHeader } from '../shared/seat-header';

const SeatUpgradeSummary = () => {
    const { serviceFactory } = useDependency();
    const navigate = useNavigate();
    const { tx } = useParams();
    const transactionId = useRef(tx as string);
    const [displayInfo, setDisplayInfo] = useState<DisplayInfo>();
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<string>();


    // Here to make TypeScript happy. These will always exist
    /* istanbul ignore next */
    if (!transactionId.current) {
        throw new Error('Invalid transaction id!');
    }

    // Here to make TypeScript happy. These will always exist
    /* istanbul ignore next */
    if (!serviceFactory) {
        throw new Error('Service Factory is not configured!');
    }

    useEffect(() => {
        const displayService = serviceFactory.GetDisplayService();
        const getItems = async () => {
            setLoading(true);

            await displayService.Get(transactionId.current)
                .then((data) => setDisplayInfo(data))
                .catch(() => setErrorMessage(ErrorMessages.NotFound))
                .finally(() => setLoading(false))
        };

        getItems();
    }, [serviceFactory]);

    return (
        <Fragment>
            <div
                aria-live="polite"
                className="content-wrapper">
                <SeatHeader></SeatHeader>
                <div id="content">

                    {displayInfo &&
                        <CountdownTimer timeout={displayInfo.expirationTime}></CountdownTimer>
                    }

                    <MessageBar message={errorMessage} data-testid="message-bar"></MessageBar>

                    {displayInfo &&
                        <Fragment>
                            <SummaryDisplay displayInfo={displayInfo}></SummaryDisplay>
                            <button
                                id="pay"
                                className="button primary"
                                onClick={() => navigate("/seat-upgrade-payment/" + tx)}
                                data-testid="pay-button">Make Payment</button>
                        </Fragment>
                    }
                </div>

                {loading && <LoadingDots></LoadingDots>}

            </div>
        </Fragment>
    );
}

export { SeatUpgradeSummary };