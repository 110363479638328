import { Fragment } from 'react';
import { MessageBar } from '../shared/message-bar';
import { ErrorMessages } from '../../../models/error-messages';
import { SeatHeader } from '../shared/seat-header';

const SeatUpgradeTimeout = () => {
    return (
        <Fragment>
            <div className="content-wrapper">
                <SeatHeader></SeatHeader>
                <div id="content">
                    <MessageBar message={ErrorMessages.Expired} data-testid="message-bar"></MessageBar>
                </div>
            </div>
        </Fragment>
    );
}

export { SeatUpgradeTimeout };