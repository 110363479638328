import sadCloud from '../../assets/error-logo.svg';
import './not-found-error-page.scss';
import { TransactionHeader } from "./transaction-header";

  export function NotFoundErrorPage() {
   
    return (
      <div className="error-page">
        <TransactionHeader />
        <img src={sadCloud} alt="Illustration of a sad cloud" />
        <h2>Something went wrong on our end.</h2>
        <main >
          <p >Please try again</p> 
          <div className="button-wrapper">
            <auro-button  onClick={()=>window.history.back()} fluid>
              Go back 
            </auro-button>
          </div>
        </main>
      </div>
    );
  }

