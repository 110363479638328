import { ConfirmationResponse } from "../../models/confirmation-response";

interface IConfirmationService {
    Get(id: string): Promise<ConfirmationResponse>;
}

class ConfirmationService implements IConfirmationService {
    async Get(id: string): Promise<ConfirmationResponse> {
        const cart = await fetch(`/api/confirmationInfo/${id}`)
            .then(async response => {
                const mappedResponse: ConfirmationResponse = {
                    ok: response.ok,
                    confirmationInfo: response.ok ? await response.json() : null
                }
                return mappedResponse;
            }).catch(() => {
                const mappedResponse: ConfirmationResponse = {
                    ok: false
                }
                return mappedResponse;
            });

        return cart;
    }
}

export { type IConfirmationService, ConfirmationService };
